.right-container {
  width: 50%;
  float: right;
  margin-top: 10px;
  text-align: center;
}
.left-container {
  width: 50%;
  float: left;
}
.digitelex-rating-control .MuiSvgIcon-root {
  width: 3em !important;
  height: 4em !important;
}
