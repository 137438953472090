h1,
h2,
h3,
h4,
h5,
h6,
p {
  font: 600 16px / 1.5 "Open Sans", sans-serif;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 600 16px / 18px "Open Sans", sans-serif;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

.login-container {
  background-image: url("https://images.unsplash.com/photo-1504021624863-054aa77f753f?ixlib=rb-0.3.5&s=bd06f5edaecf29f31d8a74519c2c1b45&auto=format&fit=crop&w=1650&q=80");
  background-position: bottom;
  background-size: cover;
  display: -webkit-box;
  display: flex;
  height: calc(100vh - 1px);
  position: relative;
  overflow: hidden;
  width: 100vw;
}
.login-container::before {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.75;
  z-index: 1;
}

.login-area {
  border-radius: 6px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  margin: auto;
  max-width: 860px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 5;
  position: relative;
}

.login-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 36px 0;
  width: 100%;
  z-index: 2;
}

.login-wrapper {
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  width: 100%;
}
.login-wrapper.is-reverse .is-right {
  -webkit-box-ordinal-group: 2;
  order: 2;
}
.login-wrapper.is-reverse .is-left {
  -webkit-box-ordinal-group: 3;
  order: 1;
}
.login-wrapper.has-no-image .is-right {
  -webkit-box-flex: 0;
  flex: 0;
  display: none;
}

.login-box {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-flex: 1;
  flex: 1;
  height: 100%;
  max-height: 420px;
  -webkit-transition: -webkit-box-flex 0.3s ease-out;
  transition: -webkit-box-flex 0.3s ease-out;
  transition: flex 0.3s ease-out;
  transition: flex 0.3s ease-out, -webkit-box-flex 0.3s ease-out;
  width: 70%;
  margin-bottom: 25px;
}
.login-box > * {
  width: 100%;
}
.login-box.is-right {
  background-image: url("https://images.unsplash.com/photo-1513603354154-93eebb9e03b0?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=97ce36bbb821bca3279fccd45de033bf&auto=format&fit=crop&w=648&q=80");
  background-position: bottom;
  background-size: cover;
  position: relative;
}
.login-box.is-right::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ed6ea0),
    to(#ec8c69)
  );
  background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
  opacity: 0.25;
  z-index: 1;
}

.form-wrapper {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  padding: 24px 12px;
  z-index: 3;
}
.form-wrapper h3 {
  align-self: flex-start;
  color: #d37d6b;
  margin-bottom: 24px;
  text-align: left;
  width: 100%;
}
.form-wrapper p {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 12px;
}
.form-wrapper a {
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  text-decoration: none;
  position: relative;
  margin: 12px 0;
  padding-bottom: 6px;
  position: relative;
  -webkit-transition: ease-out 0.3s 0.1s;
  transition: ease-out 0.3s 0.1s;
  width: auto;
}
.form-wrapper a::after {
  height: 2px;
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ed6ea0),
    to(#ec8c69)
  );
  background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.form-wrapper a:hover::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

form {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column wrap;
  max-width: 320px;
  width: 100%;
}
form input,
form button {
  border: 0;
  border-radius: 4px;
  padding: 12px 20px;
  width: 100%;
}
form label {
  font-size: 16px;
  color: #483a31;
  margin-bottom: 12px;
}
form input {
  outline: none;
  background: rgba(0, 0, 0, 0.1);
  font-size: 16px;
  margin-bottom: 12px;
}
form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ec8c69 inset !important;
  -webkit-text-fill-color: #fbfbfb !important;
  box-shadow: 0 0 0px 1000px #ec8c69 inset;
}
form button {
  outline: none;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ed6ea0),
    to(#ec8c69)
  );
  background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
form button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
}

.form-group {
  display: -webkit-box;
  display: flex;
  margin-bottom: unset !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
}
@media (max-width: 768px) {
  .login-container {
    background-image: url("https://images.unsplash.com/photo-1513603354154-93eebb9e03b0?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=97ce36bbb821bca3279fccd45de033bf&auto=format&fit=crop&w=648&q=80");
    background-position: bottom;
    background-size: cover;
    min-height: calc(100vh - 1px);
    overflow: auto;
    position: relative;
    /* padding-bottom: 48px; */
    max-width: 100% !important;
  }
  .login-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ed6ea0),
      to(#ec8c69)
    );
    background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
    opacity: 0.25;
    z-index: 1;
  }

  .login-area::before {
    background-blend-mode: screen;
  }

  .login-header {
    padding: 0px 0;
  }

  .login-wrapper {
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#d5d4d0),
      color-stop(1%, #d5d4d0),
      color-stop(31%, #eeeeec),
      color-stop(75%, #efeeec),
      to(#e9e9e7)
    );
    background-image: linear-gradient(
      to top,
      #d5d4d0 0%,
      #d5d4d0 1%,
      #eeeeec 31%,
      #efeeec 75%,
      #e9e9e7 100%
    );
    padding-bottom: 24px;
    padding-top: 18px;
  }
  .login-wrapper .is-right {
    -webkit-box-flex: 0;
    flex: 0;
  }

  .form-wrapper {
    -webkit-box-pack: center;
    justify-content: center;
  }
  .form-wrapper h3 {
    text-align: center;
  }

  .login-box {
    -webkit-box-flex: 1;
    flex: 1;
    -webkit-transition: -webkit-box-flex 0.4s ease-out;
    transition: -webkit-box-flex 0.4s ease-out;
    transition: flex 0.4s ease-out;
    transition: flex 0.4s ease-out, -webkit-box-flex 0.4s ease-out;
    width: 100%;
  }

  a::after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.checkbox {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.checkbox-label {
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  position: relative;
  padding: 6px 12px 0 28px;
}

.is-rembered {
  display: none;
}

/* empty checkbox appearance */
.checkbox-label::before {
  border: 2px solid #ed6ea0;
  border-radius: 4px;
  content: " ";
  display: block;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
  z-index: -1;
}

/* Checked */
.checkbox:checked + label {
  color: #fff;
  padding-left: 10px;
}
.checkbox:checked + label.is-rembered {
  display: block;
}
.checkbox:checked + label.is-rembered + .checkbox-label {
  display: none;
}

.checkbox:checked + label::before {
  background-color: #ed6ea0;
  height: 100%;
  top: 3px;
  width: 100%;
}

label,
label::before {
  -webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
