.body {
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
}

.container {
  text-align: center;
  margin: -8px;
  font-family: 'Roboto', sans-serif;
  height: auto;
}

.container-head {
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-left: 10px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(12, 12, 29, 0.6306897759103641) 38%,
    rgba(0, 212, 255, 1) 100%
  );
}

.menu {
  margin-top: -130px;
  text-align: left;
  color: white;
  line-height: 8px;
  cursor: pointer;
}

menu p {
  padding: 0px;
}
.profile-picture img {
  border-radius: 100%;
  margin-top: -60px;
  border: solid;
  border-width: 4px;
  border-color: white;
}

.container-body {
  padding-left: 20px;
}

.name {
  text-transform: uppercase;
}

.location {
  color: gray;
  margin: -10px;
}

.bio p {
  padding: 10px;
}

.stats {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats p {
  color: gray;
  font-size: 12px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.followers {
  padding-right: 60px;
}

.following {
  padding-right: 60px;
}

.topics {
  padding-right: 60px;
}

@media (min-width: 600px) {
  .container {
    width: 60%;
    border-radius: 2px;
    position: absolute;
    margin: 0px 0px 30px 200px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);
  }

  .container-head {
    border-radius: 2px;
  }
}

.ui-center {
  text-align: center;
}

h1 {
  margin: 1.5em;
  font-size: 24px;
  text-align: center;
}

.carousel-container {
  position: relative;
  height: 100px;
  max-width: 960px;
  margin: 0 auto;
  overflow-y: hidden;
  touch-action: pan-y;
}

.carousel-track {
  display: flex;
  height: 100%;
}

.carousel-card {
  flex: 0 0 270px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.carousel-card-inner {
  flex-direction: column;
  width: 260px;
  height: 80px;
  border-radius: 5px;
}

.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.carousel-text {
  padding: 1em;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}

.carousel-pagination-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.carousel-pagination {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 180px;
  padding: 0;
}

.carousel-pagination li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(50, 50, 50, 0.5);
}

.carousel-pagination .current {
  border-radius: 0;
  background: #ff8d00;
}
.logout-container {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 9999;
}
.logout-image {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.survey-stat {
  font-size: 14px;
  color: white;
  position: relative;
  margin-left: 195px;
  margin-top: 8px;
}
.active-surveys-container {
  margin-top: 375px;
}

@media only screen and (max-width: 600px) {
  .active-surveys-container {
    margin-top: 25px !important;
  }
}
